var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var WE=function(){},XE=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(b),$CLJS.p.g(" ")].join(""),a],null)},YE=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.kf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.VE,$CLJS.TE)),";background-color:",$CLJS.p.g($CLJS.M.h($CLJS.VE,c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],
null)},ZE=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.kf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.VE,c))].join(""),$CLJS.G(["color:black"]))],null)},$E=function(a){return $CLJS.M.h($CLJS.MD,a).value},Yea=function(a){var b=$E(a);return $CLJS.m(function(){var c=$E($CLJS.bE);return $CLJS.Im.h?$CLJS.Im.h(c,b):$CLJS.Im.call(null,c,b)}())?$CLJS.HE:$CLJS.m(function(){var c=$E($CLJS.aE);return $CLJS.Im.h?
$CLJS.Im.h(c,b):$CLJS.Im.call(null,c,b)}())?$CLJS.RE:$CLJS.m(function(){var c=$E($CLJS.ZD);return $CLJS.Im.h?$CLJS.Im.h(c,b):$CLJS.Im.call(null,c,b)}())?$CLJS.KE:$CLJS.m(function(){var c=$E($CLJS.UD);return $CLJS.Im.h?$CLJS.Im.h(c,b):$CLJS.Im.call(null,c,b)}())?$CLJS.QE:$CLJS.m(function(){var c=$E($CLJS.$D);return $CLJS.Im.h?$CLJS.Im.h(c,b):$CLJS.Im.call(null,c,b)}())?$CLJS.SE:$CLJS.m(function(){var c=$E($CLJS.WD);return $CLJS.Im.h?$CLJS.Im.h(c,b):$CLJS.Im.call(null,c,b)}())?$CLJS.NE:$CLJS.m(function(){var c=
$E($CLJS.RD);return $CLJS.Im.h?$CLJS.Im.h(c,b):$CLJS.Im.call(null,c,b)}())?$CLJS.OE:$CLJS.PE},Zea=function(a){function b(c,d){return d>=c}a=$E(a);if(b($E($CLJS.bE),a))return"error";if(b($E($CLJS.aE),a))return"warn";if(b($E($CLJS.ZD),a))return"info";b($E($CLJS.UD),a);return"log"},aF=function(a){return function(b){var c=$CLJS.Qf(b),d=$CLJS.M.h(c,$CLJS.SD),e=$CLJS.M.h(c,$CLJS.Xu);b=$CLJS.M.h(c,$CLJS.cE);e=Zea(e);e=$CLJS.Ua(console,e);e=$CLJS.m(e)?e:console.log;$CLJS.R.h(e,a.g?a.g(c):a.call(null,c));
return $CLJS.m(b)?(c=["[",$CLJS.p.g(d),"]"].join(""),d=$CLJS.p.g(b),b=b.stack,e.D?e.D(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},bF=function bF(a,b){for(;;){if($CLJS.F.h($CLJS.GE,b))return ZE(a,", ",$CLJS.PE);if($CLJS.F.h($CLJS.IE,b))return XE(a);if(b instanceof $CLJS.N)return ZE(a,b,$CLJS.KE);if(b instanceof $CLJS.w)return ZE(a,b,$CLJS.QE);if("string"===typeof b)return ZE(a,$CLJS.aj.l($CLJS.G([b])),$CLJS.JE);if($CLJS.Ig(b)){var d=XE(function(){var f=a,g=$CLJS.tc(b);return bF.h?bF.h(f,g):bF.call(null,
f,g)}()),e=$CLJS.uc(b);return bF.h?bF.h(d,e):bF.call(null,d,e)}if(b instanceof $CLJS.k||b instanceof $CLJS.Rh)return d=a,d=ZE(d,"{",$CLJS.UE),d=$CLJS.Sb(bF,d,$CLJS.pg($CLJS.GE,b)),ZE(d,"}",$CLJS.UE);if($CLJS.oe(b))return d=a,d=ZE(d,["#",$CLJS.p.g(function(){var f=$CLJS.Ob(b),g=f.name;return $CLJS.je(g)?$CLJS.aj.l($CLJS.G([f])):g}())," "].join(""),$CLJS.LE),d=ZE(d,"{",$CLJS.UE),d=$CLJS.Sb(bF,d,$CLJS.pg($CLJS.GE,b)),ZE(d,"}",$CLJS.UE);if($CLJS.le(b))return d=a,d=ZE(d,"#{",$CLJS.UE),d=$CLJS.Sb(bF,d,
$CLJS.pg($CLJS.IE,b)),ZE(d,"}",$CLJS.UE);if($CLJS.qe(b))return d=a,d=ZE(d,"[",$CLJS.UE),d=$CLJS.Sb(bF,d,$CLJS.pg($CLJS.IE,b)),ZE(d,"]",$CLJS.UE);if(b instanceof $CLJS.ah)d=ZE(a,"#queue ",$CLJS.LE),e=$CLJS.oh.h($CLJS.Lg,b),a=d,b=e;else{if($CLJS.we(b))return d=a,d=ZE(d,"(",$CLJS.LE),d=$CLJS.Sb(bF,d,$CLJS.pg($CLJS.IE,b)),ZE(d,")",$CLJS.LE);if(null!=b?b.K&16384||$CLJS.t===b.Rj||(b.K?0:$CLJS.Nb(WE,b)):$CLJS.Nb(WE,b))d=ZE(a,"#atom ",$CLJS.LE),e=$CLJS.r(b),a=d,b=e;else if($CLJS.wj(b))d=ZE(a,"#uuid ",$CLJS.LE),
e=$CLJS.p.g(b),a=d,b=e;else if($CLJS.Ib(b))d=ZE(a,"#js ",$CLJS.LE),e=$CLJS.Sb(function(f,g){return function(l,n){return $CLJS.U.j(l,$CLJS.Oi.g(n),$CLJS.Ua(g,n))}}(a,b),$CLJS.P,Object.keys(b)),a=d,b=e;else if($CLJS.Db(b))d=ZE(a,"#js ",$CLJS.LE),e=$CLJS.oh.h($CLJS.Lg,b),a=d,b=e;else return ZE(a,$CLJS.aj.l($CLJS.G([b])),$CLJS.ME)}}};$CLJS.$ea=aF(function(a){a=$CLJS.Qf(a);$CLJS.M.h(a,$CLJS.Xu);var b=$CLJS.M.h(a,$CLJS.SD),c=$CLJS.M.h(a,$CLJS.zk);$CLJS.M.h(a,$CLJS.cE);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),c],null)});
$CLJS.afa=aF(function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Xu);a=$CLJS.M.h(b,$CLJS.SD);var d=$CLJS.M.h(b,$CLJS.zk);$CLJS.M.h(b,$CLJS.cE);b=Yea(c);d=bF(XE(YE(YE(YE(new $CLJS.S(null,2,5,$CLJS.T,["",$CLJS.Lg],null),"[",b),a,b),"]",b)),d);a=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.nf(a,d)});
$CLJS.bfa=aF(function(a){a=$CLJS.Qf(a);$CLJS.M.h(a,$CLJS.Xu);var b=$CLJS.M.h(a,$CLJS.SD),c=$CLJS.M.h(a,$CLJS.zk);$CLJS.M.h(a,$CLJS.cE);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),$CLJS.aj.l($CLJS.G([c]))],null)});