var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var DX,EX,FX,HX,IX,JX,KX;DX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);EX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
FX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.GX=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);HX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
IX=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);JX=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);KX=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.xV($CLJS.iJ,$CLJS.G([$CLJS.wu,$CLJS.lJ,$CLJS.Qt,$CLJS.nL]));$CLJS.BL.o(null,$CLJS.GX,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.CL(a);return $CLJS.le(a)?(a=$CLJS.Ez.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.OI,null,$CLJS.SJ,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.mV($CLJS.GX,$CLJS.OL);
for(var LX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dK,$CLJS.LK],null)),MX=null,NX=0,OX=0;;)if(OX<NX){var PX=MX.$(null,OX);$CLJS.xV(PX,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null),$CLJS.Qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.nL],null)]));$CLJS.mV(PX,$CLJS.GX);OX+=1}else{var QX=$CLJS.A(LX);if(QX){var RX=QX;if($CLJS.re(RX)){var SX=$CLJS.$c(RX),Ima=$CLJS.ad(RX),Jma=SX,Kma=$CLJS.E(SX);LX=Ima;MX=Jma;NX=Kma}else{var TX=$CLJS.C(RX);$CLJS.xV(TX,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.iq,$CLJS.IL],null),$CLJS.Qt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.nL],null)]));$CLJS.mV(TX,$CLJS.GX);LX=$CLJS.D(RX);MX=null;NX=0}OX=0}else break}
for(var UX=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.EI,$CLJS.YH,$CLJS.aK,$CLJS.wK,$CLJS.gK,$CLJS.DI,$CLJS.SK],null)),VX=null,WX=0,XX=0;;)if(XX<WX){var Lma=VX.$(null,XX);$CLJS.xV(Lma,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)]));XX+=1}else{var YX=$CLJS.A(UX);if(YX){var ZX=YX;if($CLJS.re(ZX)){var $X=$CLJS.$c(ZX),Mma=$CLJS.ad(ZX),Nma=$X,Oma=$CLJS.E($X);UX=Mma;VX=Nma;WX=Oma}else{var Pma=$CLJS.C(ZX);$CLJS.xV(Pma,$CLJS.G([$CLJS.wu,
$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)]));UX=$CLJS.D(ZX);VX=null;WX=0}XX=0}else break}$CLJS.xV($CLJS.hJ,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.EV],null)]));
for(var aY=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.SK,null,$CLJS.gK,null,$CLJS.wK,null,$CLJS.jK,null,$CLJS.DI,null,$CLJS.YH,null,$CLJS.EI,null,$CLJS.aK,null],null),null)),bY=null,cY=0,dY=0;;)if(dY<cY){var Qma=bY.$(null,dY);$CLJS.xV(Qma,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)]));dY+=1}else{var eY=$CLJS.A(aY);if(eY){var fY=eY;if($CLJS.re(fY)){var gY=$CLJS.$c(fY),Rma=$CLJS.ad(fY),Sma=gY,Tma=$CLJS.E(gY);
aY=Rma;bY=Sma;cY=Tma}else{var Uma=$CLJS.C(fY);$CLJS.xV(Uma,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)]));aY=$CLJS.D(fY);bY=null;cY=0}dY=0}else break}$CLJS.jL(KX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.vI,$CLJS.qJ,$CLJS.IJ],null));
$CLJS.zV($CLJS.cK,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,KX],null)],null)],null)],null)]));
$CLJS.jL(JX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.tL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Gt,new $CLJS.k(null,2,[$CLJS.cv,"valid timezone ID",$CLJS.ev,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.rl);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.UL.tz.names()))],null));
$CLJS.zV($CLJS.uI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$K,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,JX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rF,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,JX],null)],null)],null)],null)]));
$CLJS.mV($CLJS.uI,$CLJS.GX);$CLJS.xV($CLJS.DJ,$CLJS.G([$CLJS.wu,$CLJS.WI]));$CLJS.jL(FX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.yL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.OH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OI,$CLJS.SJ],null))}],null)],null));
$CLJS.jL(DX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.xL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,FX],null)],null)],null)],null));
$CLJS.tV($CLJS.FI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ul,new $CLJS.k(null,1,[$CLJS.cv,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.FI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,DX],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zt,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ul,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.tX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.vX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.oX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.GV],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ul,new $CLJS.k(null,1,[$CLJS.cv,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.mB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.xX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.DV],null)],null)],null)],null)],null)],null));
$CLJS.BL.o(null,$CLJS.FI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.mB)?$CLJS.F.h(a,$CLJS.Pj)?$CLJS.SJ:$CLJS.m($CLJS.oV($CLJS.GV,a))?$CLJS.OI:$CLJS.SJ:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.BX,b))?$CLJS.OI:$CLJS.m($CLJS.Di($CLJS.CX,b))?$CLJS.OI:null:null;if($CLJS.m(a))return a;b=$CLJS.CL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.OH(c,$CLJS.Ml)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.jL(IX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hm,$CLJS.mB],null),$CLJS.Qt],null));
$CLJS.zV($CLJS.rJ,$CLJS.G([$CLJS.wu,$CLJS.SJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,IX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.nL],null)],null)],null)],null)]));
$CLJS.xV($CLJS.YD,$CLJS.G([$CLJS.wu,$CLJS.XK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.QL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.FV],null)]));$CLJS.jL(EX,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.Gt,$CLJS.fJ,$CLJS.hk,$CLJS.bm,$CLJS.XI,$CLJS.UK,$CLJS.ZK,$CLJS.vl,$CLJS.nk,$CLJS.bk,$CLJS.Sk,$CLJS.II],null));$CLJS.jL(HX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Gt,$CLJS.vI,$CLJS.qJ,$CLJS.IJ],null));
$CLJS.zV($CLJS.oK,$CLJS.G([$CLJS.wu,$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.BJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,$CLJS.IL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,EX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iq,HX],null)],null)],
null)],null)]));