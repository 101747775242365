var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.test.check.random.js");require("./clojure.test.check.rose_tree.js");
'use strict';var Xw,Yw,Zw,$w,ax,bx,cx,Zba,dx,ex,$ba,aca,gx,ix,kx,bca,mx,nx,ox,cca,rx,dca,eca,sx,ux,vx,yx,fca,zx,gca,Dx,Ex,hca,ica,Jx,Lx,mca,oca,Tx,Vx,Wx,Xx,rca,fx,wx,px,hx,ay,qx,Ax,sca;$CLJS.Ww=function(a){return a-1};Xw=function(a){if(null!=a&&null!=a.Se)a=a.Se(a);else{var b=Xw[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Xw._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IRandom.rand-long",a);}return a};
Yw=function(a){if(null!=a&&null!=a.jg)a=a.jg(a);else{var b=Yw[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Yw._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IRandom.rand-double",a);}return a};Zw=function(a,b){if(null!=a&&null!=a.kg)a=a.kg(a,b);else{var c=Zw[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Zw._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("IRandom.split-n",a);}return a};$w=function(a){return a.root};
ax=function(a,b){this.root=a;this.children=b;this.C=16;this.K=0};bx=function(a,b){return new ax(a,b)};cx=function(a,b,c,d,e){this.M=a;this.f=b;this.Dg=c;this.seed=d;this.next=e;this.C=26083532;this.K=1};Zba=function(a,b){return new cx(null,a,null,b,null)};
dx=function(a,b,c){return $CLJS.Jd(b)?new $CLJS.gf(null,$CLJS.r(b),null,1,null):$CLJS.nf(b,new $CLJS.yf(null,function(){var d=$CLJS.A(c);if(d){var e=dx;var f=$CLJS.C(d);f=a.h?a.h(b,f):a.call(null,b,f);d=e(a,f,$CLJS.zd(d))}else d=null;return d},null,null))};
ex=function(a){return function d(c){return new $CLJS.yf(null,function(){for(var e=c;;){var f=$CLJS.A(e);if(f){var g=f,l=$CLJS.C(g),n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);if(f=$CLJS.A(function(u,v,x,y,B,H){return function Y(Q){return new $CLJS.yf(null,function(aa,ha,qa,Ea){return function(){for(;;){var jb=$CLJS.A(Q);if(jb){if($CLJS.re(jb)){var lb=$CLJS.$c(jb),Fb=$CLJS.E(lb),Mb=$CLJS.Bf(Fb);a:for(var ac=0;;)if(ac<Fb){var Tb=$CLJS.be(lb,ac);Tb=$CLJS.U.j(a,Ea,Tb);Mb.add(Tb);ac+=1}else{lb=!0;break a}return lb?
$CLJS.Ef($CLJS.Gf(Mb),Y($CLJS.ad(jb))):$CLJS.Ef($CLJS.Gf(Mb),null)}Mb=$CLJS.C(jb);return $CLJS.nf($CLJS.U.j(a,Ea,Mb),Y($CLJS.zd(jb)))}return null}}}(u,v,x,y,B,H),null,null)}}(e,l,n,q,g,f)(n.children)))return $CLJS.qg.h(f,d($CLJS.zd(e)));e=$CLJS.zd(e)}else return null}},null,null)}($CLJS.rg.j($CLJS.Vm,a,$CLJS.Ai(0,Number.MAX_VALUE)))};$ba=function(a){return $CLJS.qg.h($CLJS.dg(function(b){return fx(b,a)},a),ex($CLJS.Mg(a)))};
aca=function(a,b){if(4<=$CLJS.E(b)){var c=$CLJS.af($CLJS.E(b),2);return new $CLJS.yf(null,function(){return $CLJS.nf(function(){var d=$CLJS.Wm.j(b,0,c);return gx.h?gx.h(a,d):gx.call(null,a,d)}(),new $CLJS.yf(null,function(){var d=$CLJS.Wm.h(b,c);d=gx.h?gx.h(a,d):gx.call(null,a,d);return new $CLJS.gf(null,d,null,1,null)},null,null))},null,null)}return null};gx=function(a,b){var c=hx(a,b);return bx(c.root,$CLJS.qg.h(aca(a,b),c.children))};
ix=function(a,b){var c=gx(a,b);a=bx(a.A?a.A():a.call(null),$CLJS.Lg);return $CLJS.je(b)?c:bx(c.root,$CLJS.nf(a,c.children))};$CLJS.jx=function(a,b,c,d){this.sd=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};kx=function(a){return new $CLJS.jx(a,null,null,null)};$CLJS.lx=function(a,b,c){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.yl);return a.h?a.h(b,c):a.call(null,b,c)};bca=function(a){return kx(function(){return a})};
mx=function(a,b){b=$CLJS.Qf(b);var c=$CLJS.M.h(b,$CLJS.yl);return kx(function(d,e){d=c.h?c.h(d,e):c.call(null,d,e);return a.g?a.g(d):a.call(null,d)})};nx=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.yl);return kx(function(d,e){d=$CLJS.Nw(d);var f=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);f=c.h?c.h(f,e):c.call(null,f,e);f=b.g?b.g(f):b.call(null,f);f=$CLJS.Qf(f);f=$CLJS.M.h(f,$CLJS.yl);return f.h?f.h(d,e):f.call(null,d,e)})};
ox=function(a){return kx(function(b,c){return $CLJS.Qm.j(function(d,e){return $CLJS.lx(d,e,c)},a,Zw(b,$CLJS.E(a)))})};$CLJS.mw=function(a,b){return mx(function(c){return px(a,c)},b)};$CLJS.qw=function(a){return bca(bx(a,$CLJS.Lg))};cca=function(a){return function(b){return mx(qx,kx(function(c,d){return px(function(e){return $CLJS.lx(a.g?a.g(e):a.call(null,e),c,d)},b)}))}};rx=function(a,b){return nx(a,cca(b))};
dca=function(a){return $CLJS.vi(function(b){return $CLJS.ki.h(0,b)},Zba(function(b){return $CLJS.af(b,2)},a))};eca=function(a){return $CLJS.rg.h(function(b){return a-b},dca(a))};sx=function(a,b,c){a=Yw(a);return $CLJS.$e(Math.floor(b+(a*(1+c)-a*b)))};$CLJS.tx=function(a){return kx(function(b,c){var d=a.g?a.g(c):a.call(null,c);return $CLJS.lx(d,b,c)})};ux=function(a,b){b=$CLJS.Qf(b);var c=$CLJS.M.h(b,$CLJS.yl);return kx(function(d){return c.h?c.h(d,a):c.call(null,d,a)})};
vx=function(a,b){return $CLJS.tx(function(c){return ux(a.g?a.g(c):a.call(null,c),b)})};yx=function(a,b){return kx(function(c){c=sx(c,a,b);return wx(function(d){return d>=a&&d<=b},xx(c))})};$CLJS.ow=function(a){return rx(yx(0,$CLJS.E(a)-1),function(b){return $CLJS.Td(a,b)})};fca=function(a,b){return $CLJS.E($CLJS.vi(function(c){return c<=b},$CLJS.zd(dx($CLJS.Gm,0,a))))};
zx=function(a){var b=$CLJS.ug($CLJS.Mm.h($CLJS.cf,$CLJS.C),a),c=$CLJS.R.h($CLJS.Gm,$CLJS.rg.h($CLJS.C,b));return kx(function(d,e){return $CLJS.lx(nx(yx(0,c-1),function(f){var g=fca($CLJS.rg.h($CLJS.C,b),f.root);return mx(function(l){return bx(l.root,new $CLJS.yf(null,function(){return $CLJS.qg.h(function(){return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);a:for(var H=0;;)if(H<y){var I=$CLJS.be(x,H);
I=$CLJS.lx($CLJS.Zd($CLJS.Td(b,I)),d,e);B.add(I);H+=1}else{x=!0;break a}return x?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}B=$CLJS.C(v);return $CLJS.nf($CLJS.lx($CLJS.Zd($CLJS.Td(b,B)),d,e),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Ai(0,g))}(),l.children)},null,null))},$CLJS.Zd($CLJS.Td(b,g)))}),d,e)})};$CLJS.nw=function(a){var b=$CLJS.Mg(a);return mx(function(c){return px(b,c)},yx(0,$CLJS.E(b)-1))};
gca=function(a,b,c,d,e){c=$CLJS.Qf(c);var f=$CLJS.M.h(c,Ax),g=$CLJS.M.h(c,$CLJS.Bx);for(c=g;;){if(0===c)throw function(){var q=new $CLJS.k(null,3,[$CLJS.zl,a,$CLJS.yl,b,$CLJS.Bx,g],null);return f.g?f.g(q):f.call(null,q)}();var l=$CLJS.Nw(d);d=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=$CLJS.lx(b,d,e);if($CLJS.m(function(){var q=n.root;return a.g?a.g(q):a.call(null,q)}()))return wx(a,n);d=l;e+=1;--c}};
$CLJS.iw=function(a){return nx($CLJS.tx(function(b){return yx(0,b)}),function(b){return mx(function(c){return ix($CLJS.X,c)},ox($CLJS.ng(b.root,a)))})};
Dx=function(a,b,c,d,e,f,g,l,n,q){var u=$CLJS.Tc($CLJS.Lg),v=$CLJS.Tc($CLJS.oi),x=e;e=f;for(var y=0;;){if($CLJS.F.h(n,y)&&$CLJS.E(u)<l)throw function(){var I=new $CLJS.k(null,3,[$CLJS.yl,d,$CLJS.Bx,n,$CLJS.Cx,g],null);return q.g?q.g(I):q.call(null,I)}();if($CLJS.F.h(n,y)||$CLJS.F.h($CLJS.E(u),g))return ix(function(){return function(){function I(Y){var aa=null;if(0<arguments.length){aa=0;for(var ha=Array(arguments.length-0);aa<ha.length;)ha[aa]=arguments[aa+0],++aa;aa=new $CLJS.z(ha,0,null)}return Q.call(this,
aa)}function Q(Y){return $CLJS.oh.h(a,Y)}I.v=0;I.B=function(Y){Y=$CLJS.A(Y);return Q(Y)};I.l=Q;return I}()}(u,v,x,e,y),function(){var I=x,Q=$CLJS.Vc(u);return c.h?c.h(I,Q):c.call(null,I,Q)}());f=$CLJS.Nw(x);var B=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);B=$CLJS.lx(d,B,e);var H=B.root;H=b.g?b.g(H):b.call(null,H);null!=$CLJS.xm(v,H)?(e+=1,y+=1,x=f):(y=$CLJS.Uc(u,B),v=$CLJS.Uc(v,H),u=y,x=f,y=0)}};Ex=function(a,b){var c=$CLJS.je(b);return c?c:$CLJS.R.h($CLJS.Pu,$CLJS.rg.h(a,b))};
hca=function(a,b){var c=$CLJS.ae(b),d=$CLJS.Mg(b);b=$CLJS.E(b);var e=b-1;return $CLJS.oh.h(c,$CLJS.C($CLJS.Sb(function(f,g){var l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);f=$CLJS.Nw(f);var n=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);var q=sx(n,g,e);n=$CLJS.T;q=new $CLJS.S(null,2,5,$CLJS.T,[g,q],null);g=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l=$CLJS.U.l(l,q,l.g?l.g(g):l.call(null,g),$CLJS.G([g,l.g?l.g(q):l.call(null,q)]));return new $CLJS.S(null,2,5,n,[l,f],null)},new $CLJS.S(null,2,5,$CLJS.T,[d,a],null),
$CLJS.Ai(0,b))))};
$CLJS.Hx=function(a,b,c,d,e,f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.Cx),l=$CLJS.M.h(f,$CLJS.Fx),n=$CLJS.M.h(f,$CLJS.Gx),q=$CLJS.M.j(f,$CLJS.Bx,10),u=$CLJS.M.j(f,Ax,function(H){return $CLJS.zj("Couldn't generate enough distinct elements!",H)}),v=$CLJS.m(d)?hca:function(H,I){return I},x=$CLJS.m(g)?g:$CLJS.m(l)?l:1;if($CLJS.m(g)){var y=function(H){return $CLJS.F.h(g,$CLJS.E(H))};return kx(function(H,I){return wx($CLJS.m(c)?$CLJS.Nm.h(y,function(Q){return Ex(b,Q)}):y,Dx(a,b,v,e,H,I,g,x,q,u))})}var B=
$CLJS.m(l)?l:0;y=$CLJS.m(n)?function(H){return B<=$CLJS.E(H)&&$CLJS.E(H)<=n}:function(H){return B<=$CLJS.E(H)};return nx($CLJS.m(n)?yx(B,n):$CLJS.tx(function(H){return yx(B,B+H)}),function(H){var I=H.root;return kx(function(Q,Y){return wx($CLJS.m(c)?$CLJS.Nm.h(y,function(aa){return Ex(b,aa)}):y,Dx(a,b,v,e,Q,Y,I,x,q,u))})})};
ica=function(a,b,c,d){for(var e=function(){var g=$CLJS.ka($CLJS.ra(b,64-a));return 0===c?Math.abs(g):g}();;){if(c<=e&&e<=d)return e;var f=-e;if(c<=f&&f<=d)return f;e=function(){var g=e;if(!$CLJS.Zf(e)){var l=0>e?$CLJS.Cu:$CLJS.Ww;return l.g?l.g(g):l.call(null,g)}return g}()/2}};Jx=function(a,b){return $CLJS.tx(function(c){c=1<c?c:1;return mx(function(d){var e=d.root;d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return xx(ica(d,e,a,b))},$CLJS.Ix.l($CLJS.G([yx(1,54>c?c:54),jca])))})};
$CLJS.rw=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.rk);b=$CLJS.M.h(b,$CLJS.Zl);var c=$CLJS.m(a)?a:kca,d=$CLJS.m(b)?b:Kx;return $CLJS.pw.h(function(e){return c<=e&&e<=d},0>=c&&0<=d?Jx(c,d):0>d?$CLJS.mw(function(e){return d+e},Jx(c-d,0)):$CLJS.mw(function(e){return c+e},Jx(0,d-c)))};Lx=function(a){if(0===a)return-1023;var b=Math.abs(a);a=Math.floor(Math.log(b)*Math.LOG2E);b*=Math.pow(2,-a);return 1>b?a-1:2<=b?a+1:a};
mca=function(a,b){function c(l,n){return $CLJS.tx(function(q){var u=1<<$CLJS.af(200<q?200:q,8);return 0>=l&&0<=n?yx(function(){var v=-u;return l>v?l:v}(),n<u?n:u):0>n?yx(function(){var v=n-u;return l>v?l:v}(),n):yx(l,function(){var v=l+u;return n<v?n:v}())})}if(null==a&&null==b)return $CLJS.Ix.l($CLJS.G([c(-1023,1023),$CLJS.nw(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))]));var d=$CLJS.m(a)?a:lca,e=$CLJS.m(b)?b:Mx,f=function(){var l=Lx(d);return-1023>l?-1023:l}(),g=function(){var l=Lx(e);return-1023>
l?-1023:l}();return 0<=d?$CLJS.Ix.l($CLJS.G([c(f,g),$CLJS.qw(1)])):0>=e?$CLJS.Ix.l($CLJS.G([c(g,f),$CLJS.qw(-1)])):$CLJS.mw(function(l){var n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);return 0>q&&f<n||0<q&&g<n?new $CLJS.S(null,2,5,$CLJS.T,[n,-q],null):l},$CLJS.Ix.l($CLJS.G([c(-1023,g>f?g:f),$CLJS.nw(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))])))};
oca=function(a,b){var c=$CLJS.m(a)?$CLJS.m(b)?function(e){return a<=e&&e<=b}:function(e){return a<=e}:$CLJS.m(b)?function(e){return e<=b}:null,d=$CLJS.mw(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=$CLJS.J(e,1,null)/Math.pow(2,52)+1;var l=e*Math.pow(2,g)*f;var n=(n=null==c)?n:c.g?c.g(l):c.call(null,l);if($CLJS.m(n))return l;f=Nx(g,f);g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=$CLJS.m(a)?g>a?g:a:g;f=$CLJS.m(b)?f<b?f:b:f;e=g+(f-g)*(e-1);e=e<f?e:f;return e>g?e:g},$CLJS.Ix.l($CLJS.G([mca(a,
b),nca])));return $CLJS.m(c)?$CLJS.pw.h(c,d):d};
$CLJS.Sx=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.j(b,$CLJS.Ox,!0);a=$CLJS.M.j(b,$CLJS.Px,!0);var d=$CLJS.M.h(b,$CLJS.rk);b=$CLJS.M.h(b,$CLJS.Zl);var e=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[95,oca(d,b)],null)],null);e=(null==d?null==b||0<=b:null==b?0>=d:0>=d&&0<=b)?$CLJS.kf.l(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.qw(0)],null),$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.qw(-0)],null)])):e;b=$CLJS.m($CLJS.m(c)?null==b:c)?$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.qw(Qx)],
null)):e;c=$CLJS.m($CLJS.m(c)?null==d:c)?$CLJS.kf.h(b,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.qw(Rx)],null)):b;a=$CLJS.m(a)?$CLJS.kf.h(c,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.qw(pca)],null)):c;return $CLJS.F.h(1,$CLJS.E(a))?$CLJS.Zd($CLJS.C(a)):zx(a)};Tx=function(a){return vx(function(b){return $CLJS.$e(Math.pow(b,.6))},a)};Vx=function(a,b,c){return $CLJS.ow(new $CLJS.S(null,12,5,$CLJS.T,[Ux,$CLJS.yw,a,b,c,qca,$CLJS.tw,$CLJS.ww,$CLJS.xw,$CLJS.Bw,$CLJS.Cw,$CLJS.Dw],null))};
Wx=function(a){if("number"===typeof a){var b=isNaN(a);a=$CLJS.m(b)?b:$CLJS.F.h(Rx,a)||$CLJS.F.h(Qx,a);a=$CLJS.Gb(a)}else a=!0;return a};Xx=function(a){return $CLJS.ow(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.lw.g(a),$CLJS.iw(a),$CLJS.kw.g($CLJS.pw.h(Wx,a)),vx(function(b){return $CLJS.af(b,2)},$CLJS.jw.h($CLJS.pw.h(Wx,a),a))],null))};rca=function(a){return kx(function(b,c){var d=$CLJS.Nw(b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.lx(a.g?a.g(b):a.call(null,b),d,c)})};
$CLJS.Zx=function(a,b){return $CLJS.tx(function(c){return rx(yx(0,$CLJS.$e(Math.pow(c,1.1))),function(d){return rca(function(e){e=Yx(d,e);var f=ux(c,b);return $CLJS.Sb(function(g,l){return rx(yx(0,10),function(n){return 0===n?f:ux(l,a.g?a.g(g):a.call(null,g))})},f,e)})})})};
$CLJS.Rw.prototype.kg=$CLJS.Ma(53,function(a,b){switch(b){case 0:return $CLJS.Lg;case 1:return new $CLJS.S(null,1,5,$CLJS.T,[this],null);default:a=b-1;var c=this.state;for(b=$CLJS.Tc($CLJS.Lg);;){if($CLJS.F.h(a,$CLJS.E(b)))return $CLJS.Vc($CLJS.If(b,new $CLJS.Rw(this.gamma,c)));var d=this.gamma.add(c);c=this.gamma.add(d);var e=$CLJS.Qw(c);d=new $CLJS.Rw(e,$CLJS.Pw(d));b=$CLJS.Uc(b,d)}}});
$CLJS.Rw.prototype.jg=$CLJS.Ma(52,function(){var a=this.Se(null);a=$CLJS.Jw(a,11);return $CLJS.Gw*(a.Ba>>>0)+$CLJS.Rba*a.ra});$CLJS.Rw.prototype.Se=$CLJS.Ma(51,function(){return $CLJS.Pw(this.state.add(this.gamma))});var $x={};ax.prototype.$=function(a,b){if($CLJS.F.h(b,0))return this.root;if($CLJS.F.h(b,1))return this.children;throw Error("Index out of bounds in rose tree");};ax.prototype.Ma=function(a,b,c){return $CLJS.F.h(b,0)?this.root:$CLJS.F.h(b,1)?this.children:c};$CLJS.h=cx.prototype;
$CLJS.h.toString=function(){return $CLJS.hd(this)};$CLJS.h.N=function(){return this.M};$CLJS.h.Ja=function(){return this.Pa(null)};$CLJS.h.wa=function(){return $CLJS.yd};$CLJS.h.ad=function(){return this.seed!==$x};$CLJS.h.Na=function(a,b){a=this.Ia(null);var c=this.f.g?this.f.g(a):this.f.call(null,a);for(a=b.h?b.h(a,c):b.call(null,a,c);;){if($CLJS.Jd(a))return $CLJS.r(a);c=this.f.g?this.f.g(c):this.f.call(null,c);a=b.h?b.h(a,c):b.call(null,a,c)}};
$CLJS.h.Oa=function(a,b,c){a=this.Ia(null);for(c=b.h?b.h(c,a):b.call(null,c,a);;){if($CLJS.Jd(c))return $CLJS.r(c);a=this.f.g?this.f.g(a):this.f.call(null,a);c=b.h?b.h(c,a):b.call(null,c,a)}};$CLJS.h.Ia=function(){$x===this.seed&&(this.seed=this.f.g?this.f.g(this.Dg):this.f.call(null,this.Dg));return this.seed};$CLJS.h.Pa=function(){null==this.next&&(this.next=new cx(null,this.f,this.Ia(null),$x,null));return this.next};$CLJS.h.ba=function(){return this};
$CLJS.h.O=function(a,b){return b===this.M?this:new cx(b,this.f,this.Dg,this.seed,this.next)};$CLJS.h.ga=function(a,b){return $CLJS.nf(b,this)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,$CLJS.Ni,"("," ",")",c,this)};fx=function fx(a,b){return new $CLJS.yf(null,function(){var d=$CLJS.A(b);if(d)if(0===a)var e=$CLJS.zd(b);else{e=$CLJS.C(d);var f=a-1;d=$CLJS.zd(d);f=fx.h?fx.h(f,d):fx.call(null,f,d);e=$CLJS.nf(e,f)}else e=null;return e},null,null)};
wx=function wx(a,b){return bx(b.root,$CLJS.rg.h(function(d){return wx.h?wx.h(a,d):wx.call(null,a,d)},$CLJS.ug(function(d){d=d.root;return a.g?a.g(d):a.call(null,d)},b.children)))};px=function px(a,b){return bx(function(){var d=b.root;return a.g?a.g(d):a.call(null,d)}(),$CLJS.rg.h(function(d){return px.h?px.h(a,d):px.call(null,a,d)},b.children))};
hx=function hx(a,b){return $CLJS.A(b)?bx($CLJS.R.h(a,$CLJS.rg.h($w,b)),$CLJS.rg.h(function(d){return hx.h?hx.h(a,d):hx.call(null,a,d)},$ba($CLJS.jg(Infinity,b)))):bx(a.A?a.A():a.call(null),$CLJS.Lg)};ay=function ay(a,b){return bx($CLJS.R.h(a,$CLJS.rg.h($w,b)),$CLJS.rg.h(function(d){return ay.h?ay.h(a,d):ay.call(null,a,d)},ex(b)))};qx=function qx(a){var c=a.root,d=c.children;return bx(c.root,$CLJS.qg.h($CLJS.rg.h(qx,a.children),d))};$CLJS.Fx=new $CLJS.N(null,"min-elements","min-elements",949370780);
Ax=new $CLJS.N(null,"ex-fn","ex-fn",-284925510);sca=new $CLJS.N(null,"max-tries-or-opts","max-tries-or-opts",-609860571);$CLJS.Px=new $CLJS.N(null,"NaN?","NaN?",-1917767651);$CLJS.Gx=new $CLJS.N(null,"max-elements","max-elements",433034073);$CLJS.Cx=new $CLJS.N(null,"num-elements","num-elements",1960422107);$CLJS.Bx=new $CLJS.N(null,"max-tries","max-tries",-1824441792);$CLJS.Ox=new $CLJS.N(null,"infinite?","infinite?",-2017886608);var xx,tca,Ux,uca,jca,Kx,kca,Qx,Rx,Mx,lca,pca,cy,nca,Nx,dy,vca,fy,wca,xca,gy,hy,iy,qca,yca,jy,Yx;$CLJS.hw={};$CLJS.h=$CLJS.jx.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "gen":return this.sd;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#clojure.test.check.generators.Generator{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yl,this.sd],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.yl],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=236843149^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.sd,b.sd)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.yl,null],null),null),b)?$CLJS.Em.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.jx(this.sd,this.F,$CLJS.Rf($CLJS.Em.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "gen":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.yl,b):$CLJS.O.call(null,$CLJS.yl,b))?new $CLJS.jx(c,this.F,this.m,null):new $CLJS.jx(this.sd,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.yl,this.sd)],null),this.m))};
$CLJS.h.O=function(a,b){return new $CLJS.jx(this.sd,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};xx=function xx(a){return bx(a,$CLJS.rg.h(xx,eca(a)))};tca=new $CLJS.k(null,2,[Ax,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.Bx);return $CLJS.zj(["Couldn't satisfy such-that predicate after ",$CLJS.p.g(b)," tries."].join(""),a)},$CLJS.Bx,10],null);
$CLJS.pw=function pw(a){switch(arguments.length){case 2:return pw.h(arguments[0],arguments[1]);case 3:return pw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.pw.h=function(a,b){return $CLJS.pw.j(a,b,10)};
$CLJS.pw.j=function(a,b,c){if($CLJS.Ae(c))c=new $CLJS.k(null,1,[$CLJS.Bx,c],null);else if(!$CLJS.oe(c))throw $CLJS.zj("Bad argument to such-that!",new $CLJS.k(null,1,[sca,c],null));var d=$CLJS.gn.l($CLJS.G([tca,c]));return kx(function(e,f){return gca(a,b,d,e,f)})};$CLJS.pw.v=3;$CLJS.tw=$CLJS.nw(new $CLJS.S(null,2,5,$CLJS.T,[!1,!0],null));$CLJS.Ix=function Ix(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ix.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
$CLJS.Ix.l=function(a){return mx(function(b){return ay($CLJS.Vm,b)},ox(a))};$CLJS.Ix.v=0;$CLJS.Ix.B=function(a){return this.l($CLJS.A(a))};$CLJS.by=$CLJS.tx(function(a){return yx(0,a)});Ux=$CLJS.tx(function(a){return yx(-a,a)});uca=$CLJS.mw(function(a){return-1*a},$CLJS.by);$CLJS.mw($CLJS.Cu,$CLJS.by);$CLJS.mw($CLJS.Ww,uca);
$CLJS.lw=function lw(a){switch(arguments.length){case 1:return lw.g(arguments[0]);case 2:return lw.h(arguments[0],arguments[1]);case 3:return lw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.lw.g=function(a){return nx($CLJS.tx(function(b){return yx(0,b)}),function(b){return mx(function(c){return ix($CLJS.Vm,c)},ox($CLJS.ng(b.root,a)))})};$CLJS.lw.h=function(a,b){return $CLJS.R.h($CLJS.Ix,$CLJS.ng(b,a))};
$CLJS.lw.j=function(a,b,c){return nx(yx(b,c),function(d){return mx(function(e){return wx(function(f){return $CLJS.E(f)>=b&&$CLJS.E(f)<=c},ix($CLJS.Vm,e))},ox($CLJS.ng(d.root,a)))})};$CLJS.lw.v=3;$CLJS.kw=function kw(a){switch(arguments.length){case 1:return kw.g(arguments[0]);case 2:return kw.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.kw.g=function(a){return $CLJS.kw.h(a,$CLJS.P)};
$CLJS.kw.h=function(a,b){return $CLJS.Hx($CLJS.oi,$CLJS.Ye,!1,!1,a,b)};$CLJS.kw.v=2;$CLJS.jw=function jw(a){switch(arguments.length){case 2:return jw.h(arguments[0],arguments[1]);case 3:return jw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.jw.h=function(a,b){return $CLJS.jw.j(a,b,$CLJS.P)};$CLJS.jw.j=function(a,b,c){return $CLJS.Hx($CLJS.P,$CLJS.C,!1,!1,$CLJS.Ix.l($CLJS.G([a,b])),c)};$CLJS.jw.v=3;
jca=kx(function(a){return bx(Xw(a),$CLJS.Lg)});Kx=$CLJS.R.h($CLJS.Hm,$CLJS.ng(53,2))-1;kca=-Kx;$CLJS.yw=$CLJS.rw($CLJS.P);Qx=Number.POSITIVE_INFINITY;Rx=Number.NEGATIVE_INFINITY;Mx=Number.MAX_VALUE;lca=-Mx;pca=Number.NaN;
cy=function cy(a){return 32>=a?yx(0,function(){switch($CLJS.$e(a)){case 32:return 4294967295;case 31:return 2147483647;default:return(1<<a)-1}}()):$CLJS.mw(function(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return 4294967296*d+c},$CLJS.Ix.l($CLJS.G([function(){var c=a-32;return cy.g?cy.g(c):cy.call(null,c)}(),cy.g?cy.g(32):cy.call(null,32)])))};
nca=$CLJS.mw(function(a){for(var b=0,c=Math.pow(2,52);;){if(1>a)return b*c;var d=a/2;c/=2;b=2*b+(a&1);a=d}},$CLJS.tx(function(a){return nx(yx(0,52>a?a:52),function(b){return cy(b.root)})}));
Nx=function Nx(a,b){return 0>b?(b=-b,b=Nx.h?Nx.h(a,b):Nx.call(null,a,b),a=$CLJS.J(b,0,null),b=$CLJS.J(b,1,null),new $CLJS.S(null,2,5,$CLJS.T,[-b,-a],null)):$CLJS.F.h(-1023,a)?new $CLJS.S(null,2,5,$CLJS.T,[0,(1*Math.pow(2,52)-1)*Math.pow(2,-1074)],null):new $CLJS.S(null,2,5,$CLJS.T,[1*Math.pow(2,a),(1*Math.pow(2,52)-1)*Math.pow(2,a-51)],null)};$CLJS.vw=$CLJS.Sx($CLJS.P);$CLJS.uw=$CLJS.mw($CLJS.Du,yx(0,255));dy=$CLJS.mw($CLJS.Du,yx(32,126));
$CLJS.ey=$CLJS.mw($CLJS.Du,$CLJS.ow(new $CLJS.S(null,3,5,$CLJS.T,[yx(48,57),yx(65,90),yx(97,122)],null)));vca=$CLJS.mw($CLJS.Du,$CLJS.ow(new $CLJS.S(null,2,5,$CLJS.T,[yx(65,90),yx(97,122)],null)));fy=$CLJS.nw(new $CLJS.S(null,7,5,$CLJS.T,"*+!-_?.".split(""),null));wca=zx(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[14,$CLJS.ey],null),new $CLJS.S(null,2,5,$CLJS.T,[7,fy],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.qw(":")],null)],null));
xca=zx(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[2,vca],null),new $CLJS.S(null,2,5,$CLJS.T,[1,fy],null)],null));gy=$CLJS.mw($CLJS.av,$CLJS.lw.g($CLJS.uw));hy=$CLJS.mw($CLJS.av,$CLJS.lw.g(dy));$CLJS.Aw=$CLJS.mw($CLJS.av,$CLJS.lw.g($CLJS.ey));
iy=$CLJS.mw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);b=$CLJS.av.g($CLJS.nf(b,a));return $CLJS.Mu($CLJS.Mu(b,/:{2,}/,":"),/:$/,"")},$CLJS.pw.h(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);a=$CLJS.J(a,0,null);b=$CLJS.m(a)?(b="+"===b||"-"===b)?!/[^0-9]/.test(a):b:a;return!$CLJS.ye(b)},$CLJS.Ix.l($CLJS.G([xca,$CLJS.lw.g(wca)]))));$CLJS.ww=Tx($CLJS.mw($CLJS.Oi,iy));
$CLJS.xw=Tx($CLJS.mw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Oi.h(b,a)},$CLJS.Ix.l($CLJS.G([iy,iy]))));$CLJS.Bw=zx(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[100,Tx($CLJS.mw($CLJS.Ui,iy))],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.qw($CLJS.Tu)],null)],null));$CLJS.Cw=Tx($CLJS.mw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ui.h(b,a)},$CLJS.Ix.l($CLJS.G([iy,iy]))));
qca=$CLJS.mw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return b/a},$CLJS.Ix.l($CLJS.G([Ux,$CLJS.mw($CLJS.Cu,$CLJS.by)])));
$CLJS.Dw=function(a){return mx(function(b){return bx(b.root,$CLJS.Lg)},a)}($CLJS.mw(function(a){function b(d){return(a.g?a.g(d):a.call(null,d)).toString(16)}var c=(((a.g?a.g(15):a.call(null,15))&3)+8).toString(16);return $CLJS.Gu([$CLJS.p.g(b(0)),$CLJS.p.g(b(1)),$CLJS.p.g(b(2)),$CLJS.p.g(b(3)),$CLJS.p.g(b(4)),$CLJS.p.g(b(5)),$CLJS.p.g(b(6)),$CLJS.p.g(b(7)),"-",$CLJS.p.g(b(8)),$CLJS.p.g(b(9)),$CLJS.p.g(b(10)),$CLJS.p.g(b(11)),"-4",$CLJS.p.g(b(12)),$CLJS.p.g(b(13)),$CLJS.p.g(b(14)),"-",$CLJS.p.g(c),
$CLJS.p.g(b(16)),$CLJS.p.g(b(17)),$CLJS.p.g(b(18)),"-",$CLJS.p.g(b(19)),$CLJS.p.g(b(20)),$CLJS.p.g(b(21)),$CLJS.p.g(b(22)),$CLJS.p.g(b(23)),$CLJS.p.g(b(24)),$CLJS.p.g(b(25)),$CLJS.p.g(b(26)),$CLJS.p.g(b(27)),$CLJS.p.g(b(28)),$CLJS.p.g(b(29)),$CLJS.p.g(b(30))].join(""))},$CLJS.lw.h(yx(0,15),31)));yca=Vx($CLJS.vw,$CLJS.uw,gy);$CLJS.zw=Vx($CLJS.vw,dy,hy);Vx($CLJS.Sx(new $CLJS.k(null,1,[$CLJS.Px,!1],null)),$CLJS.uw,gy);Vx($CLJS.Sx(new $CLJS.k(null,1,[$CLJS.Px,!1],null)),dy,hy);jy=Math.log(2);
Yx=function Yx(a,b){if(2>=a)return new $CLJS.S(null,1,5,$CLJS.T,[a],null);var d=Math.log(a);b=$CLJS.Nw(b);var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);d=$CLJS.$e(Math.exp(Yw(e)*(d-jy)+jy));e=$CLJS.af(a,d);return 1<d&&1<e?$CLJS.nf(d,Yx.h?Yx.h(e,b):Yx.call(null,e,b)):new $CLJS.S(null,1,5,$CLJS.T,[a],null)};$CLJS.zca=$CLJS.Zx(Xx,yca);$CLJS.sw=$CLJS.Zx(Xx,$CLJS.zw);